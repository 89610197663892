/** @jsx jsx */
import { jsx } from "theme-ui"
import { useWindowSize } from "react-use"

import Lightbox from "./lightbox"

const VideoLightbox = ({ url, lightboxVisible, setLightboxVisible }) => {
  const windowSize = useWindowSize()
  const embedUrl = url ? url.split("v=")[1] : ""

  return (
    <Lightbox visible={lightboxVisible} setVisible={setLightboxVisible}>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          width: "100%",
          height: `${windowSize.height}px`,
        }}
      >
        <div
          sx={{
            position: "relative",
            paddingTop: `${windowSize.height}px`,
          }}
        >
          <iframe
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
            width="100%"
            height="100%"
            src={
              lightboxVisible
                ? `https://www.youtube.com/embed/${embedUrl}?autoplay=1&playsinline=0&rel=0`
                : ""
            }
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={"1"}
            title="YouTube"
          />
        </div>
      </div>
    </Lightbox>
  )
}

export default VideoLightbox
