/** @jsx jsx */
import { jsx } from "theme-ui"
import Section from "./section"
import Container from "./container"
import Collapsible from "./collapsible"

const BlockTitle = props => (
  <div
    {...props}
    sx={{
      textAlign: "center",
      fontVariant: "normal",
      fontSize: "18px",
      color: "red",
      marginTop: "16px",
      marginBottom: "6px",
    }}
  />
)

const FTCreditGroup = props => (
  <div
    {...props}
    sx={{
      display: "flex",
      flexDirection: "row",
    }}
  />
)

const FTCreditTitle = props => (
  <div
    {...props}
    sx={{
      width: "50%",
      textAlign: "right",
      marginRight: "10px",
      marginLeft: "10px",
      paddingTop: "2px",
      paddingBottom: "2px",
    }}
  />
)

const FTCreditItemList = props => (
  <div
    {...props}
    sx={{
      width: "50%",
      marginRight: "10px",
      marginLeft: "10px",
    }}
  />
)

const FTCreditItem = props => (
  <div
    {...props}
    sx={{
      fontWeight: "normal",
      paddingTop: "2px",
      paddingBottom: "2px",
    }}
  />
)

const FicheTechnique = props => {
  if (props.list) {
    const creditsLines = props.list.match(/[^\r\n]+/g)
    return (
      <div>
        {creditsLines.map((line, i) =>
          line.indexOf(":") === -1 ? (
            <BlockTitle key={i}>{line.trim()}</BlockTitle>
          ) : (
            <FTCreditGroup key={i}>
              <FTCreditTitle>{line.split(":")[0].trim()}</FTCreditTitle>
              <FTCreditItemList>
                {line
                  .split(":")[1]
                  .split(",")
                  .map((str, j) => (
                    <FTCreditItem key={j}>{str.trim()}</FTCreditItem>
                  ))}
              </FTCreditItemList>
            </FTCreditGroup>
          )
        )}
      </div>
    )
  }
}

const SectionFilmFichetech = props => {
  const isGrey = props.isGrey
  const film = props.film
  return (
    <Section isGrey={isGrey}>
      <Section.Title1>GÉNÉRIQUE</Section.Title1>
      <Container isFull={false} sx={{ py: 3 }}>
        <Collapsible height={350} open={false} isGrey={isGrey}>
          <FicheTechnique list={film.Fiche_technique} />
        </Collapsible>
      </Container>
    </Section>
  )
}

export default SectionFilmFichetech
