/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Section from "./section"
import Container from "./container"
import Collapsible from "./collapsible"

const SectionFilmPrix = props => {
  const data = useStaticQuery(
    graphql`
      query {
        award_left: file(relativePath: { eq: "award_left.png" }) {
          childImageSharp {
            fluid(maxWidth: 47, grayscale: true) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        award_right: file(relativePath: { eq: "award_right.png" }) {
          childImageSharp {
            fluid(maxWidth: 47, grayscale: true) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  const isGrey = props.isGrey
  const film = props.film
  return (
    <Section isGrey={isGrey}>
      <Section.Title1>
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Img
            fluid={data.award_left.childImageSharp.fluid}
            sx={{
              width: `27px`,
              height: "55px",
              opacity: 0.2,
              mr: "8px",
              ml: "auto",
            }}
            alt="award_left"
          />
          <div>NOMINATIONS & PRIX</div>
          <Img
            fluid={data.award_right.childImageSharp.fluid}
            sx={{
              width: `27px`,
              height: "55px",
              opacity: 0.2,
              ml: "8px",
              mr: "auto",
            }}
            alt="award_left"
          />
        </div>
      </Section.Title1>
      <Container
        isFull={false}
        sx={{
          py: 3,
          align: "center",
        }}
      >
        <Collapsible height={200} open={false} isGrey={isGrey}>
          {film.Nominations_prix &&
            film.Nominations_prix.split("\n").map((item, i) => (
              <p key={i} sx={{ my: 3, textAlign: "center" }}>
                {item}
              </p>
            ))}
        </Collapsible>
      </Container>
    </Section>
  )
}

export default SectionFilmPrix
