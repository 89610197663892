/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { useMeasure } from "react-use"
import { useSpring, animated } from "react-spring"
import * as easings from "d3-ease"

const Collapsible = ({ open, isGrey, children, ...props }) => {
  const [containerOpen, setContainerOpen] = useState(open ? open : false)
  const [ref, { height }] = useMeasure()
  const minHeight = props.height ? props.height : 100
  const heightTooSmall = minHeight > height
  const collapsedHeight = heightTooSmall ? height : minHeight
  const propsSpring = useSpring({
    heightAnum: containerOpen || heightTooSmall ? height + 16 : collapsedHeight, // +16 is bug ?
    config: {
      duration: 1000,
      easing: easings.easeCubicInOut,
    },
  })
  return (
    <div>
      <div
        sx={{
          position: "relative",
        }}
      >
        <animated.div
          style={{
            height: propsSpring.heightAnum,
            overflow: "hidden",
          }}
        >
          <div ref={ref}>{children}</div>
        </animated.div>
        <div
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "10%",
            background:
              containerOpen || heightTooSmall
                ? `linear-gradient(to bottom, rgba(255,255,255,0) 1%,rgba(246,246,246,0) 100%)`
                : isGrey
                ? `linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(246,246,246,1) 100%)`
                : `linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)`,
          }}
        ></div>
      </div>
      {!heightTooSmall && (
        <div onClick={() => setContainerOpen(!containerOpen)}>
          <p
            sx={{
              mb: 0,
              textAlign: "center",
              color: "#999",
              fontSize: "0.90em",
              fontWeight: 300,
            }}
          >
            {containerOpen ? "voir moins..." : "voir plus..."}
          </p>
        </div>
      )}
    </div>
  )
}

export default Collapsible
