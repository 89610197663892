/** @jsx jsx */
import { useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { jsx, Styled } from "theme-ui"
import { MdPlayArrow } from "react-icons/md"
import { AiOutlineRight } from "react-icons/ai"
// import Imgix from "react-imgix"
// import ImgixClient from "imgix-core-js"
// import "lazysizes"
// import srcset from "srcset"
// import { useWindowSize } from "react-use"
// import parseDuration from 'parse-duration'

import "array-flat-polyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MultilineText from "../components/multiline-text"
import Section from "../components/section"
import Container from "../components/container"
import SectionFilmPrix from "../components/section-film-prix"
import SectionFilmVideos from "../components/section-film-videos"
import SectionFilmPhotos from "../components/section-film-photos"
import SectionFilmFichetech from "../components/section-film-fichetech"
import ActualiteItem from "../components/actualite-item"
import ActualiteCollection from "../components/actualite-collection"
import playIcon from "../images/play.svg"
import LightboxVideo from "../components/lightbox-video"
import FilmItem from "../components/film-item"
import FilmCollection from "../components/film-collection"
import FilmItemEnprod from "../components/film-item-enprod"
// import DownloadOverlay from '../components/download-overlay'

const MainBox = props => (
  <div
    {...props}
    sx={{
      display: ["block", "flex"],
      flexDirection: "row",
    }}
  />
)

const AfficheBox = ({ children, ...props }) => (
  <div
    {...props}
    sx={{
      display: ["none", "flex"],
      justifyContent: "center",
      width: ["100%", "30%"],
      mt: [0, "-180px"],
      alignItems: "flex-start",
    }}
  >
    {children}
  </div>
)

const Affiche = ({ film, data, opacity, ...props }) =>
  film.Affiche_optimized &&
  film.Affiche_optimized.childImageSharp &&
  film.Affiche_optimized.childImageSharp.fluid && (
    <div
      {...props}
      sx={{
        width: ["100%", "90%"],
      }}
    >
      <div sx={{ backgroundColor: "#000" }}>
        <Img
          sx={{ opacity: opacity ? opacity : 1 }}
          fadeIn={true}
          durationFadeIn={100}
          fluid={film.Affiche_optimized.childImageSharp.fluid}
          alt={film.Titre_FR}
          placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
        />
      </div>
      <VODbutton film={film} data={data} />
    </div>
  )

const VODbutton = ({ film, data }) => {
  return (
    film.VOD_universcine_URL && (
      <a
        href={film.VOD_universcine_URL}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ textDecoration: "none", color: "#000" }}
      >
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 3,
            mx: 2,
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              border: "solid 2px",
              borderRadius: "30px",
              mr: 2,
              padding: "1px 7px 1px 4px",
              opacity: 0.3,
              ":hover": {
                opacity: 0.7,
                cursor: "pointer",
              },
            }}
          >
            <MdPlayArrow size={"20px"} sx={{ display: "block" }} />
            <div sx={{ my: 0, fontWeight: 500, fontSize: "14px" }}>VOD</div>
          </div>

          <Img
            fluid={data.logoUniverscine.childImageSharp.fluid}
            sx={{ width: "120px", mb: [`4px`, `4px`, `5px`], opacity: 0.5 }}
            alt="Logo"
            placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
          />
        </div>
      </a>
    )
  )
}

const TitleBox = props => (
  <div
    {...props}
    sx={{
      width: ["100%", "70%"],
      px: [0, 1, 2, 3],
    }}
  />
)

function compareCreationSortie(a, b) {
  if (a.Sortie < b.Sortie) {
    return 1
  }
  if (a.Sortie > b.Sortie) {
    return -1
  }
  return 0
}

function compareActualitePublication_date(a, b) {
  if (a.Publication_date < b.Publication_date) {
    return 1
  }
  if (a.Publication_date > b.Publication_date) {
    return -1
  }
  return 0
}

// remove the duplicate objects from an array
function getUnique(arr, comp) {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e])
    .map(e => arr[e])

  return unique
}

export default function Template({ data, location }) {
  const [lightboxVisible, setLightboxVisible] = useState(false)
  const film = data.bubbleCreation
  const actualites = film.fields.actualites.sort(
    compareActualitePublication_date
  )
  const creationReal_ = film.Realisateurs.map(
    real => real.fields.creations
  ).flat()
  const creationReal = getUnique(creationReal_, "id")
  const filmsReal = creationReal
    .filter(creation => !creation.isArtNum)
    .filter(film => film.Sortie)
    .sort(compareCreationSortie)

  let isGrey = false

  // const isSorti = film.Statut_ === "1555693173056x139561298485978430"
  const isSorti = film.Statut.Statut === "Sorti"

  // const windowSize = useWindowSize()
  return (
    <Layout>
      <SEO
        title={film.Titre_FR ? film.Titre_FR : ""}
        description={film.Synopsis_FR ? film.Synopsis_FR : ""}
        creation={film}
        location={location}
      />
      <div sx={{ position: "relative" }}>
        {film.Image_principale_optimized &&
          film.Image_principale_optimized.childImageSharp &&
          isSorti && (
            <Img
              sx={{
                width: "100%",
                height: [0, "calc(90vh - 60px)"],
                maxHeight: "900px",
                display: ["none", "inherit"],
              }}
              fadeIn={true}
              durationFadeIn={300}
              fluid={film.Image_principale_optimized.childImageSharp.fluid}
              placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
            />
          )}
        <div
          sx={{
            display: ["inherit", "none"],
            mt: "-5px",
          }}
        >
          <Affiche opacity={0.95} film={film} data={data} />
        </div>

        {!isSorti && (
          <div
            sx={{
              width: "calc(90vh - 60px)",
              maxWidth: "100%",
              mx: "auto",
            }}
          >
            <FilmItemEnprod
              isFluid={true}
              film={film}
              isBgGray={false}
              isTitle={false}
            />
          </div>
        )}
        <div
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LightboxVideo
            url={film.Video_principale_URL}
            lightboxVisible={lightboxVisible}
            setLightboxVisible={setLightboxVisible}
          />
          {film.Video_principale_URL && film.Video_principale_URL !== "" && (
            <div onClick={() => setLightboxVisible(true)}>
              <img
                sx={{
                  width: "120px",
                  opacity: [0.8, 0.6],
                  cursor: "pointer",
                  ":hover": {
                    opacity: [1, 0.8],
                  },
                }}
                src={playIcon}
                alt="playIcon"
              />
            </div>
          )}
        </div>
      </div>

      <Section isGrey={false}>
        <Container isFull={false} isText={true}>
          <MainBox>
            {film.Affiche_optimized && (
              <AfficheBox>
                <Affiche film={film} data={data} />
              </AfficheBox>
            )}
            <TitleBox>
              <Styled.h1 sx={{ mt: -2 }}>{film.Titre_FR}</Styled.h1>
              <Styled.p sx={{ my: 3, fontWeight: 400 }}>
                {film.Realisateurs &&
                  film.Realisateurs.map((realisateur, i) => (
                    <Styled.strong key={i}>
                      <Link
                        to={`/personne/${realisateur.slug}`}
                        sx={{
                          textDecoration: "none",
                          color: "#000",
                          display: "inline-block",
                        }}
                      >
                        <div
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {i > 0 ? (
                            <span sx={{ ml: 2 }}>
                              {realisateur.CACHE_Nom_complet}
                            </span>
                          ) : (
                            realisateur.CACHE_Nom_complet
                          )}
                          <AiOutlineRight
                            color={"#CCC"}
                            size={"0.9em"}
                            sx={{ ml: 1 }}
                          />
                        </div>
                      </Link>
                    </Styled.strong>
                  ))}
              </Styled.p>
              <MultilineText text={film.Synopsis_FR} />
              <div sx={{ my: 3 }}>
                {film.Statut.Statut === "Sorti" && (
                  <Styled.p sx={{ my: 0 }}>
                    <Styled.strong>Sortie : </Styled.strong>
                    {new Date(film.Sortie).getFullYear()}
                  </Styled.p>
                )}
                {film.Statut.Statut === "Sorti" && (
                  <Styled.p sx={{ my: 0 }}>
                    <Styled.strong>Durée : </Styled.strong>
                    {film.Duree}
                  </Styled.p>
                )}
                {film.Cast && (
                  <Styled.p sx={{ my: 0, textAlign: "justify" }}>
                    <Styled.strong>Cast : </Styled.strong> {film.Cast}
                  </Styled.p>
                )}
              </div>
            </TitleBox>
          </MainBox>
        </Container>
      </Section>

      {// check if there are videos in the media array
      film.Medias &&
        film.Medias.some(
          media => !media.DELETED && media.Type === "VIDEO" && media.Video_URL
        ) && <SectionFilmVideos isGrey={(isGrey = !isGrey)} film={film} />}

      {// check if there are photos in the media array
      film.Medias && film.Medias.some(media => media.Type === "PHOTO") && (
        <SectionFilmPhotos isGrey={(isGrey = !isGrey)} film={film} />
      )}

      {film.Nominations_prix && (
        <SectionFilmPrix isGrey={(isGrey = !isGrey)} film={film} />
      )}

      {film.Fiche_technique && (
        <SectionFilmFichetech isGrey={(isGrey = !isGrey)} film={film} />
      )}

      {actualites && actualites.some(actualite => !actualite.DELETED) && (
        <Section isGrey={(isGrey = !isGrey)}>
          <Container isFull={false}>
            <Section.Title1 sx={{ marginBottom: "22px" }}>
              ACTUALITÉ
            </Section.Title1>
            <ActualiteCollection>
              {actualites.map(
                actualite =>
                  actualite.Image_principale_optimized && (
                    <ActualiteItem
                      isFluid={true}
                      key={actualite.id}
                      actualite={actualite}
                    />
                  )
              )}
            </ActualiteCollection>
          </Container>
        </Section>
      )}

      {filmsReal &&
        filmsReal.length > 1 &&
        filmsReal.some(
          creations => !creations.isArtNum && !creations.DELETED
        ) && (
          <Section isGrey={(isGrey = !isGrey)}>
            <Container isFull={false}>
              <Section.Title1 sx={{ marginBottom: "22px" }}>
                FILMOGRAPHIE
              </Section.Title1>
              <FilmCollection>
                {filmsReal.map(
                  film =>
                    film.Affiche_optimized &&
                    !film.isArtNum &&
                    !film.DELETED && (
                      <FilmItem
                        isFluid={true}
                        key={film.id}
                        film={film}
                        year={true}
                      />
                    )
                )}
              </FilmCollection>
            </Container>
          </Section>
        )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    bubbleCreation(id: { eq: $id }) {
      id
      Titre_FR
      CACHE_Realisateurs
      Cast
      Duree
      Fiche_technique
      Nominations_prix
      Sortie
      Statut_
      Statut {
        Statut
      }
      Synopsis_FR
      Video_principale_URL
      slug
      VOD_universcine_URL
      IMDB_URL
      Wikipedia_URL
      isArtNum
      EnProdMedias {
        Titre
        Image_optimized {
          childImageSharp {
            fluid(maxWidth: 350, maxHeight: 350, quality: 85) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
        Image
        Image_meta {
          h
          w
        }
      }

      Realisateurs {
        CACHE_Nom_complet
        slug
        IMDB_URL
        Wikipedia_URL
        Allocine_URL
        Image_principale_optimized {
          childImageSharp {
            fluid(maxWidth: 350, maxHeight: 350, quality: 85) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
        fields {
          creations {
            id
            Affiche_optimized {
              publicURL # for Affiche download
              childImageSharp {
                fluid(
                  maxWidth: 286
                  maxHeight: 400
                  quality: 85
                  cropFocus: CENTER
                ) {
                  # ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluid
                }
              }
            }
            Sortie
            slug
            isArtNum
          }
        }
      }

      Affiche
      Affiche_optimized {
        publicURL # for Affiche download
        childImageSharp {
          fluid(maxWidth: 375, quality: 90, cropFocus: CENTER) {
            # ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluid
          }
        }
      }

      Image_principale
      Image_principale_optimized {
        childImageSharp {
          fluid(
            maxWidth: 1600
            maxHeight: 830
            quality: 85
            cropFocus: CENTER
          ) {
            # ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluid
          }
        }
      }

      fields {
        actualites {
          Description_FR
          Titre_FR
          PUBLIE
          Lien_URL
          Featured
          DELETED
          id
          Publication_date
          Image_principale_optimized {
            childImageSharp {
              fluid(
                maxWidth: 350
                maxHeight: 350
                quality: 85
                cropFocus: CENTER
              ) {
                # ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      Medias: Medias {
        Titre
        Type
        Video_URL
        Video_type
        id
        Nom_du_fichier
        Fichier
        Image
        DELETED
        Image_meta {
          h
          w
        }
      }
    }

    logoUniverscine: file(relativePath: { eq: "logo_Universcine.png" }) {
      childImageSharp {
        fluid(maxWidth: 120, grayscale: true) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
