/** @jsx jsx */
import { jsx } from "theme-ui"
import { useWindowSize, useKey } from "react-use"
import { MdClose } from "react-icons/md"

const Lightbox = ({ visible, setVisible, children }) => {
  const windowSize = useWindowSize()
  useKey("Escape", a => setVisible(false))

  return (
    <div
      sx={{
        position: "fixed",
        top: "0px",
        zIndex: "2000",
        width: "100vw",
        height: `${windowSize.height}px`,
        backgroundColor: "black",
        display: visible ? "flex" : "none",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          color: "white",
          cursor: "pointer",
          opacity: 0.6,
          backgroundColor: "#333",
          ":hover": { opacity: 1 },
          zIndex: 3000,
        }}
        onClick={() => setVisible(false)}
      >
        <MdClose
          size={"3rem"}
          sx={{ cursor: "pointer", px: "0.5rem", pt: "0.3rem", pb: "0rem" }}
        />
      </div>

      {children}
    </div>
  )
}

export default Lightbox
